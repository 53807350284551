



















































import { Component, Prop, Vue } from 'vue-property-decorator'
import Modal from '@/app/ui/components/Modal/index.vue'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import Button from '@/app/ui/components/Button/index.vue'
import controller from '@/app/ui/controllers/ManageCourierController'

@Component({
  components: {
    Modal,
    TextInput,
    Button,
  },
})
export default class ModalCourierLeaveApprovalReject extends Vue {
  @Prop({ type: Boolean, default: false }) readonly visible!: boolean
  @Prop({ type: String, default: '', required: true }) leaveId!: string

  controller = controller

  reason = ''

  get disableButton(): boolean {
    return this.reason.length < 10
  }

  public onCancel(): void {
    this.reason = ''
    this.$emit('cancel')
  }

  public onSubmit(): void {
    this.$emit('submit')
    this.controller.submitCourierLeaveApproval({
      leaveId: this.leaveId,
      note: this.reason,
      status: 'REJECTED',
    })
    this.reason = ''
  }
}
