function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"flex flex-row gap-5"},[_c('div',{staticClass:"flex flex-col gap-2 w-2/5"},[_c('span',{staticClass:"text-bgMatterhorn font-semibold required"},[_vm._v("Cari")]),_c('TextInput',{attrs:{"data-testid":"courier-leave-approval__search","type":"search","placeholder":"Nama Kurir, No. HP, Plat Nomor, Kurir ID"},on:{"input":_vm.onSearch},model:{value:(_vm.parameters.search),callback:function ($$v) {_vm.$set(_vm.parameters, "search", $$v)},expression:"parameters.search"}})],1),_c('div',{staticClass:"flex flex-col gap-2 w-1/4"},[_c('span',{staticClass:"text-bgMatterhorn font-semibold"},[_vm._v("Tipe Cuti/Libur")]),_c('DropdownSelect',{attrs:{"data-testid":"courier-leave-approval__leave-type","placeholder":"Pilih","options":_vm.leaveTypeOptions},on:{"input":_vm.onFilterLeaveType},model:{value:(_vm.parameters.leaveType),callback:function ($$v) {_vm.$set(_vm.parameters, "leaveType", $$v)},expression:"parameters.leaveType"}})],1),_c('div',{staticClass:"flex flex-col gap-2 w-1/5"},[_c('span',{staticClass:"text-bgMatterhorn font-semibold"},[_vm._v("Kurir")]),_c('DropdownSelect',{attrs:{"data-testid":"courier-leave-approval__courier-type","placeholder":"Pilih","options":_vm.courierTypeOptions},on:{"input":_vm.onFilterCourierType},model:{value:(_vm.parameters.courierType),callback:function ($$v) {_vm.$set(_vm.parameters, "courierType", $$v)},expression:"parameters.courierType"}})],1)]),_c('div',{staticClass:"py-6 data-table"},[_c('DataTableV2',{attrs:{"headers":_vm.headers,"data-items":_vm.listData,"isLoading":_vm.controller.isLoading},scopedSlots:_vm._u([{key:"header-1",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5 items-center cursor-pointer",attrs:{"data-testid":"courier-leave-approval__table-sort-courier-name"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.COURIER_NAME)}}},[_c('span',{staticClass:"w-32 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.COURIER_NAME_ASC,
              }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.COURIER_NAME_DESC,
              }},[_c('AscendingIcon')],1)])])]}},{key:"header-3",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-2 items-center cursor-pointer",attrs:{"data-testid":"courier-leave-approval__table-sort-date-leave"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.DATE_LEAVE)}}},[_c('span',{staticClass:"w-36 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.DATE_LEAVE_ASC,
              }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.DATE_LEAVE_DESC,
              }},[_c('AscendingIcon')],1)])])]}},{key:"1",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"w-full text-sm text-gray-4",domProps:{"innerHTML":_vm._s(data.data.value)}})]}},{key:"7",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-4"},[_c('Button',{attrs:{"data-testid":("courier-leave-approval__button-approve-" + (data.data.value)),"buttonStyle":"green"},on:{"click":function () { return _vm.onApproveCourierLeaveRequest(data.data.value); }}},[_c('span',{staticClass:"text-sm"},[_vm._v("Diterima")])]),_c('Button',{attrs:{"data-testid":("courier-leave-approval__button-reject-" + (data.data.value)),"buttonStyle":"outline"},on:{"click":function () { return _vm.onRejectCourierLeaveRequest(data.data.value); }}},[_c('span',{staticClass:"text-sm"},[_vm._v("Ditolak")])])],1)]}},{key:"data-empty",fn:function(){return [_c('div',{staticClass:"w-full flex justify-center"},[_c('EmptyState',{attrs:{"type":"search","text":"Data Kurir Tidak Ditemukan","description":"Silahkan cek kembali nama, nomor hp dan plat kurir yang masukkan","customClass":"mt-24","classText":"mt-6 text-sm","classDesc":"mt-2 w-72 text-center text-sm text-secondaryText"}})],1)]},proxy:true}])}),(_vm.listData.length)?_c('PaginationNav',{staticClass:"mb-8 mt-2",attrs:{"data-testid":"courier-leave-approval__pagination","enablePerPageOption":true,"perPageOptions":_vm.perPageOptions,"page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"totalItem":_vm.controller.paginationData.totalItem,"lang":"id"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return (function () {
          _vm.fetchList()
        })($event)},"input":function () {
          _vm.fetchList()
        },"onChangeOption":_vm.onChangePerPage},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}}):_vm._e()],1),(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e(),_c('ModalCourierLeaveApprovalReject',{attrs:{"data-testid":"courier-leave-approval__modal-reject","visible":_vm.showCourierLeaveRejectModal,"leaveId":_vm.courierLeaveApprovalLeaveId},on:{"submit":function () {
        _vm.showCourierLeaveRejectModal = false
      },"cancel":function () {
        _vm.showCourierLeaveRejectModal = false
        _vm.courierLeaveApprovalLeaveId = ''
      }}}),_c('ModalConfirm',{attrs:{"data-testid":"courier-leave-approval__modal-approve","visible":_vm.showCourierLeaveApproveModal,"title":"Yakin Ingin Terima Permintaan Cuti?","description":"Pastikan data cuti sudah sesuai","actionBtnLabel":"Ya, Terima"},on:{"cancel":function () {
        _vm.showCourierLeaveApproveModal = false
        _vm.courierLeaveApprovalLeaveId = ''
      },"action":_vm.submitApproveCourierLeave}}),_c('ModalSuccess',{attrs:{"data-testid":"courier-leave-approval__modal-success-approve","visible":_vm.showCourierLeaveSuccessApproveModal,"title":"Pengajuan Cuti/Libur Diterima","description":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-full"},[_c('Button',{attrs:{"buttonStyle":"red w-full"},on:{"click":function () {
            _vm.showCourierLeaveSuccessApproveModal = false
          }}},[_c('span',{staticClass:"text-xs"},[_vm._v("Oke")])])],1)]},proxy:true}])}),_c('ModalSuccess',{attrs:{"data-testid":"courier-leave-approval__modal-success-reject","visible":_vm.showCourierLeaveSuccessRejectModal,"title":"Pengajuan Cuti/Libur Ditolak","description":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-full"},[_c('Button',{attrs:{"buttonStyle":"red w-full"},on:{"click":function () {
            _vm.showCourierLeaveSuccessRejectModal = false
          }}},[_c('span',{staticClass:"text-xs"},[_vm._v("Oke")])])],1)]},proxy:true}])}),_c('div',{staticClass:"modal-failed"},[_c('ModalFailed',{attrs:{"data-testid":"courier-leave-approval__modal-failed","visible":_vm.showCourierLeaveFailedModal,"title":"Terjadi kendala pengajuan cuti. Cek lagi","description":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',{staticClass:"w-full"},[_c('Button',{attrs:{"buttonStyle":"red w-full"},on:{"click":function () {
            _vm.showCourierLeaveFailedModal = false
          }}},[_c('span',{staticClass:"text-xs"},[_vm._v("Oke")])])],1)]},proxy:true}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }