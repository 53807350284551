























































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import TextInput from '@/app/ui/components/TextInput/index.vue'
import DropdownSelect from '@/app/ui/components/DropdownSelect/index.vue'
import DateTimePickerV2 from '@/app/ui/components/DateTimePickerV2/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import EmptyState from '@/app/ui/components/EmptyState/EmptyState.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { Utils } from '@/app/infrastructures/misc/Utils'
import controller from '@/app/ui/controllers/ManageCourierController'
import { ManageCourierLeaveRequest } from '@/domain/entities/ManageCourier'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import {
  IHeaderCell,
  ITableCell,
} from '@/data/infrastructures/misc/interfaces/manageCourier'
import Button from '@/app/ui/components/Button/index.vue'
import {
  CourierLeaveApprovalSortFields,
  CourierLeaveApprovalSortType,
} from '@/app/infrastructures/misc/Constants/manageCourier'
import AscendingIcon from '@/app/ui/assets/ascending_icon.vue'
import ModalCourierLeaveApprovalReject from '@/app/ui/views/ManageUserAndCourier/components/ModalCourierLeaveApprovalReject/index.vue'
import ModalConfirm from '../../components/ModalConfirm/index.vue'
import ModalFailed from '../../components/ModalFailed/index.vue'
import ModalSuccess from '../../components/ModalSuccess/index.vue'

interface IParams {
  search: string
  courierType: IOptions | undefined
  leaveType: IOptions | undefined
  page: number
  perPage: number
}

interface IOptions {
  label: string
  value: string | number | boolean
}

@Component({
  components: {
    TextInput,
    DropdownSelect,
    DateTimePickerV2,
    DataTableV2,
    EmptyState,
    PaginationNav,
    LoadingOverlay,
    Button,
    AscendingIcon,
    ModalCourierLeaveApprovalReject,
    ModalConfirm,
    ModalFailed,
    ModalSuccess,
  },
})
export default class CourierLeaveApprovalPage extends Vue {
  controller = controller

  enumSelectedSort = CourierLeaveApprovalSortType
  enumSortFields = CourierLeaveApprovalSortFields

  selectedSort: CourierLeaveApprovalSortType | null = null

  showCourierLeaveRejectModal = false
  showCourierLeaveApproveModal = false
  showCourierLeaveFailedModal = false
  showCourierLeaveSuccessApproveModal = false
  showCourierLeaveSuccessRejectModal = false
  courierLeaveApprovalLeaveId = ''

  listData: ITableCell[][] = []
  courierTypeOptions = [
    { label: 'Semua', value: '' },
    { label: 'KVP', value: 'KVP' },
    { label: 'POS', value: 'POS' },
  ]

  leaveTypeOptions = [
    { label: 'Semua', value: '' },
    { label: 'Seharian', value: 'FULLDAY' },
    { label: 'Setengah Hari', value: 'HALFDAY' },
  ]

  parameters: IParams = {
    search: '',
    courierType: this.courierTypeOptions[0],
    leaveType: this.leaveTypeOptions[0],
    page: 1,
    perPage: 10,
  }

  headers = [
    this.headerCellMapper('No.', '50px', '0px', '0px'),
    this.headerCellMapper('Nama Kurir', '220px'),
    this.headerCellMapper('POS', '200px'),
    this.headerCellMapper('Tanggal Cuti/Libur', '188px'),
    this.headerCellMapper('Jam Cuti/Libur', '140px'),
    this.headerCellMapper('Tipe Cuti/Libur', '160px'),
    this.headerCellMapper('Alasan Cuti/Libur', '188px'),
    this.headerCellMapper('Atur', '257px'),
  ]

  perPageOptions: IOptions[] = [
    { label: '10', value: 10 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ]

  created(): void {
    document.title =
      'Daftar Pengajuan Cuti/Libur | Content Management System | Lionparcel'
    this.controller.getCourierLeaveTotalRequest()
    this.fetchList(true)
  }

  get params(): Record<string, string | number | undefined> {
    return {
      page: this.parameters.page,
      perPage: this.parameters.perPage,
      keyword: this.parameters.search,
      courierTypeValid: this.parameters.courierType
        ? <string>this.parameters.courierType.value
        : 'ALL',
      leaveType: this.parameters.leaveType
        ? <string>this.parameters.leaveType.value
        : 'ALL',
      timezone: encodeURIComponent(
        Utils.formatDateWithIDLocale(new Date().toISOString(), 'Z')
      ),
      sortBy: this.selectedSort ? this.selectedSort.split('-')[0] : undefined,
      sortDirection: this.selectedSort
        ? this.selectedSort.split('-')[1]
        : 'desc',
    }
  }

  public fetchList(reset = false): void {
    if (reset) {
      this.parameters.page = 1
    }

    this.controller.getCourierLeaveRequestList(this.params)
  }

  public onFilter(): void {
    this.fetchList(true)
  }

  public onFilterCourierType(value: IOptions): void {
    if (!value) {
      this.parameters.courierType = this.courierTypeOptions[0]
    }

    this.onFilter()
  }

  public onFilterLeaveType(value: IOptions): void {
    if (!value) {
      this.parameters.leaveType = this.leaveTypeOptions[0]
    }

    this.onFilter()
  }

  public onSearch = Utils.debounce((value: string) => {
    if ((value && value.length > 2) || !value) {
      this.fetchList(true)
    }
  }, 500)

  public onChangePerPage(perPage: number): void {
    this.parameters.perPage = perPage
    this.fetchList(true)
  }

  public onRejectCourierLeaveRequest(id: number): void {
    this.showCourierLeaveRejectModal = true
    this.courierLeaveApprovalLeaveId = id.toString()
  }

  public onApproveCourierLeaveRequest(id: number): void {
    this.courierLeaveApprovalLeaveId = id.toString()
    this.showCourierLeaveApproveModal = true
  }

  public submitApproveCourierLeave(): void {
    this.showCourierLeaveApproveModal = false
    this.controller.submitCourierLeaveApproval({
      leaveId: this.courierLeaveApprovalLeaveId,
      note: '',
      status: 'APPROVED',
    })
  }

  public onTableSort(sortType: CourierLeaveApprovalSortFields): void {
    switch (sortType) {
      case CourierLeaveApprovalSortFields.COURIER_NAME:
        if (
          this.selectedSort === CourierLeaveApprovalSortType.COURIER_NAME_ASC
        ) {
          this.selectedSort = CourierLeaveApprovalSortType.COURIER_NAME_DESC
        } else if (
          this.selectedSort === CourierLeaveApprovalSortType.COURIER_NAME_DESC
        ) {
          this.selectedSort = null
        } else {
          this.selectedSort = CourierLeaveApprovalSortType.COURIER_NAME_ASC
        }
        break

      case CourierLeaveApprovalSortFields.DATE_LEAVE:
        if (this.selectedSort === CourierLeaveApprovalSortType.DATE_LEAVE_ASC) {
          this.selectedSort = CourierLeaveApprovalSortType.DATE_LEAVE_DESC
        } else if (
          this.selectedSort === CourierLeaveApprovalSortType.DATE_LEAVE_DESC
        ) {
          this.selectedSort = null
        } else {
          this.selectedSort = CourierLeaveApprovalSortType.DATE_LEAVE_ASC
        }
        break
    }

    this.fetchList(true)
  }

  private formatedLeaveType(value: boolean): string {
    if (value) {
      return 'Setengah Hari'
    } else {
      return 'Seharian'
    }
  }

  private headerCellMapper(
    title: string | number,
    colWidth: string,
    paddingRight?: string,
    paddingLeft?: string
  ): IHeaderCell {
    return {
      title: title,
      customStyle: {
        minWidth: colWidth,
        maxWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '20px',
      },
    }
  }

  private tableCellMapper(
    value: string | number | Date | string[],
    colWidth: string,
    paddingLeft?: string,
    paddingRight?: string
  ): ITableCell {
    return {
      value,
      customStyle: {
        maxWidth: colWidth,
        minWidth: colWidth,
        paddingRight: paddingRight || '0px',
        paddingLeft: paddingLeft || '24px',
      },
    }
  }

  @Watch('controller.courierLeaveRequestData')
  onCourierLeaveRequestDataChanged(
    val: ManageCourierLeaveRequest[] | undefined
  ): void {
    this.listData = <ITableCell[][]>(
      val?.map((item, index) => [
        this.tableCellMapper(
          index +
            1 +
            this.parameters.perPage * (this.parameters.page - 1) +
            '.',
          '50px',
          '0px',
          '0px'
        ),
        this.tableCellMapper(
          `[${item.courier?.courierId}] ${item.courier?.fullName} </br> (${item.courier?.courierTypeValid}) ${item.courier?.phoneNumber}`,
          '220px'
        ),
        this.tableCellMapper(
          item.courier?.partnerName || '-',
          '200px',
          '20px',
          '4px'
        ),
        this.tableCellMapper(
          Utils.formatDateWithIDLocale(<string>item.dateLeave, 'DD MMMM YYYY'),
          '188px'
        ),
        this.tableCellMapper(
          `${Utils.formatDateWithIDLocale(
            <string>item.startTime,
            'HH:mm'
          )} - ${Utils.formatDateWithIDLocale(<string>item.endTime, 'HH:mm')}`,
          '140px'
        ),
        this.tableCellMapper(
          this.formatedLeaveType(<boolean>item.isCustomLeave),
          '160px'
        ),
        this.tableCellMapper(<string>item.reason, '188px'),
        this.tableCellMapper(<number>item.id, '257px'),
      ])
    )
  }

  @Watch('controller.isSuccessApproveCourierLeave')
  onIsSuccessApproveCourierLeaveChanged(val: boolean): void {
    if (val) {
      this.showCourierLeaveSuccessApproveModal = true
      this.fetchList(true)
      this.controller.setSuccessApproveCourierLeave(false)
      this.controller.getCourierLeaveTotalRequest()
    }
  }

  @Watch('controller.isSuccessRejectCourierLeave')
  onIsSuccessRejectCourierLeaveChanged(val: boolean): void {
    if (val) {
      this.showCourierLeaveSuccessRejectModal = true
      this.fetchList(true)
      this.controller.setSuccessRejectCourierLeave(false)
      this.controller.getCourierLeaveTotalRequest()
    }
  }

  @Watch('controller.isFailedSubmitCourierLeave')
  onIsFailedSubmitCourierLeaveChanged(val: boolean): void {
    if (val) {
      this.showCourierLeaveFailedModal = true
      this.fetchList(true)
      this.controller.setFailedSubmitCourierLeave(false)
      this.controller.getCourierLeaveTotalRequest()
    }
  }
}
